import React from "react";
import Grid from '@mui/material/Grid';

import { ProductCard } from "../components/ProductCard";

import { type Product } from "../types/Model";

interface Props {
    products: Product[]
}

export const ProductGrid: React.FC<Props> = ({ products }) => {
    return (
        <Grid container spacing={0} columns={{ xs: 2, sm: 2, md: 4 }}>
            {products.map((product) => (
                <Grid xs={1} key={product.id} padding={1}>
                    <ProductCard product={product}></ProductCard>
                </Grid>
            ))}
        </Grid>
    );
};
