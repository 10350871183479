import { type RouteProps, Navigate } from 'react-router';

import { CatalogPage } from '../pages/CatalogPage';
import { ProductPage } from '../pages/ProductPage';

export const routes: RouteProps[] = [
  { path: '/', element: <CatalogPage /> },
  { path: '/products/:productId', element: <ProductPage /> },
  { path: '*', element: <Navigate to={'/'} /> }
];
