import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Container } from '@mui/material';

import { Pagination, Spinner } from "@telegram-apps/telegram-ui";

import { ProductGrid } from '../components/ProductGrid';
import { type Product } from '../types/Model';

import { useThemeParams } from "@telegram-apps/sdk-react";


interface CatalogData {
    products: Product[],
    page: number,
    max_page: number,
}

const getCatalog = async (page: number = 1): Promise<CatalogData> => {
    const response = await fetch(`/api/products?page=${page}`);
    const responseObject: CatalogData = await response.json();
    return responseObject;
};

export const CatalogPage: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const themeParams = useThemeParams();
    const page = parseInt(new URLSearchParams(location.search).get('page') || '1');

    const [catalogData, setCatalogData] = useState<CatalogData | null>(null);
    useEffect(() => {
        try {
            getCatalog(page).then(setCatalogData);
        } finally {
        }
    }, [page, setCatalogData]);

    if (catalogData === null) {
        return <Spinner size="l" />;
    }

    const { products, max_page } = catalogData;

    return (
        <Container fixed disableGutters style={{ background: themeParams.secondaryBgColor }}>
            <Box sx={{ flexGrow: 1 }}>
                <ProductGrid products={products}></ProductGrid>
            </Box>
            <Pagination boundaryCount={1} siblingCount={1} hideNextButton hidePrevButton page={page} count={max_page} onChange={(_, newPage) => {
                navigate(`${location.pathname}?page=${newPage}`);
            }} />
        </Container>
    );
};
