import { Container, Stack, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { retrieveLaunchParams, useThemeParams } from '@telegram-apps/sdk-react';

import { type Product, type Variant } from '../types/Model';
import { Button, Snackbar, Chip, Divider, Spinner, Title } from '@telegram-apps/telegram-ui';

interface ProductData {
    product: Product;
}

function addToCart(setLoading: (loading: boolean) => void, product: Product, variant: Variant) {
    setLoading(true);

    const params = retrieveLaunchParams();
    fetch("/api/web_app/cart_add", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId: params.initData?.user?.id, product, variant })
    }).finally(() => {
        setLoading(false);
    });
}


const getProduct = async (productId: string): Promise<ProductData> => {
    const response = await fetch(`/api/products/${productId}`);
    const product: Product = await response.json();
    return { product };
}

export const ProductPage: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [isAddToCartSnackbarShown, setIsAddToCartSnackbarShown] = useState(false);
    const [selectedSizeIdx, setSelectedSizeIdx] = useState(0);
    const themeParams = useThemeParams();

    const { productId } = useParams();

    const [productData, setProductData] = useState<ProductData | null>(null);
    useEffect(() => {
        try {
            if (productId !== undefined) {
                getProduct(productId).then(setProductData);
            }
        } finally {
        }
    }, [productId, setProductData]);

    if (productData === null) {
        return <Spinner size="l" />;
    }

    const { product } = productData;
    const selectedVariant = product.variants[selectedSizeIdx];

    return (
        <Container fixed disableGutters>
            <Box>
                <img width={"100%"} src={product.img_url} alt={product.name}></img>
            </Box>
            <Box padding={1}>
                <Title plain weight='1' style={{color: themeParams.sectionHeaderTextColor}}>{product.name}</Title>
            </Box>
            <Box style={{ background: themeParams.secondaryBgColor }} borderRadius={2} padding={1} paddingBottom={2}>
                <Title level='1' plain weight='1' style={{color: themeParams.textColor}}>{selectedVariant.price}</Title>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <Box>
                    <Title plain weight='1' style={{color: themeParams.subtitleTextColor}}>Размер (EU)</Title>
                    <Stack direction={'row'} spacing={1} overflow={'scroll'} flexShrink={0}>
                        {product.variants.map(({ size, price }, idx) => (
                            <Box flexShrink={0} key={idx}>
                                <Chip
                                    mode={idx === selectedSizeIdx ? "elevated" : "outline"}
                                    style={{ margin: 1 }}
                                    onClick={(_) => { setSelectedSizeIdx(idx) }}
                                >
                                    {size}<br />{price}
                                </Chip>
                            </Box>
                        ))}
                    </Stack>
                </Box>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <Button
                    onClick={
                        () => {
                            addToCart(setLoading, product, product.variants[selectedSizeIdx]);
                            setIsAddToCartSnackbarShown(true);
                        }
                    }
                    loading={loading}
                    disabled={loading}
                >
                    В корзину
                </Button>
                {isAddToCartSnackbarShown && (
                    <Snackbar onClose={() => setIsAddToCartSnackbarShown(false)}>
                        Товар добавлен в корзину
                    </Snackbar>
                )}
            </Box>
        </Container>
    );
};
