import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import { type Product } from '../types/Model';
import { useNavigate } from 'react-router-dom';
import { useThemeParams } from '@telegram-apps/sdk-react';
import { Title } from '@telegram-apps/telegram-ui';

interface Props {
    product: Product;
};

export const ProductCard: React.FC<Props> = ({ product }) => {
    const minPrice = product.variants.map(v => v.price).reduce((a: string, b: string) => a < b ? a : b);
    const themeParams = useThemeParams();
    const navigate = useNavigate();

    return (
        <Card square={false} style={{ boxShadow: 'none', background: themeParams.sectionBgColor, height: '100%' }}>
            <CardActionArea style={{ height: '100%' }} onClick={() => navigate(`/products/${product.id}`)}>
                <CardMedia
                    component="img"
                    image={product.img_url}
                    alt={product.name}
                />
                <CardContent style={{ color: themeParams.textColor }}>
                    <Title level="2" plain weight="1">{minPrice}</Title>
                    <Title level="3" plain weight='3'>{product.name}</Title>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};
