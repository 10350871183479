import { SDKProvider, useLaunchParams } from '@telegram-apps/sdk-react';
import { type FC } from 'react';

import { App } from './App';
import { RedirectToTelegram } from '../pages/RedirectToTelegram';
import { ErrorBoundary } from './ErrorBoundary';

const Inner: FC = () => {
  const debug = useLaunchParams().startParam === 'debug';

  return (
    <SDKProvider acceptCustomStyles debug={debug}>
      <App />
    </SDKProvider>
  );
};

export const Root: FC = () => (
  <ErrorBoundary fallback={RedirectToTelegram}>
    <Inner />
  </ErrorBoundary>
);
